export const SET_ASIGNMENTS_TEST_FILE = 'SET_ASIGNMENTS_TEST_FILE'
export const SET_ASIGNMENTS_STUDENT_TEAMS = 'SET_ASIGNMENTS_STUDENT_TEAMS'
export const EMPTY_ASIGNMENTS_STUDENT_TEAMS = 'EMPTY_ASIGNMENTS_STUDENT_TEAMS'

export const SET_ASIGNMENTS_COURSE_INSTANCE = 'SET_ASIGNMENTS_COURSE_INSTANCE'
export const SET_ASIGNMENTS_COURSE_INSTANCE_LOADING =
  'SET_ASIGNMENTS_COURSE_INSTANCE_LOADING'

export const SET_ASSIGNMENT = 'SET_ASSIGNMENT'
export const SET_REVIEW_PROGRESS = 'SET_REVIEW_PROGRESS'
