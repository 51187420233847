export const SET_COURSE_INSTANCE = 'SET_COURSE_INSTANCE'
export const CLEAR_COURSE_INSTANCE = 'CLEAR_COURSE_INSTANCE'

// results
export const RESULTS_ADD_GRADING = 'RESULTS_ADD_GRADING'
export const RESULTS_UPDATE_GRADING = 'RESULTS_UPDATE_GRADING'
export const RESULTS_REMOVE_GRADING = 'RESULTS_REMOVE_GRADING'

export const RESULTS_ADD_TYPE = 'RESULTS_ADD_TYPE'
export const RESULTS_UPDATE_TYPE = 'RESULTS_UPDATE_TYPE'
export const RESULTS_REMOVE_TYPE = 'RESULTS_REMOVE_TYPE'


//documents
export const SET_CURRENT_DOCUMENTS = 'SET_CURRENT_DOCUMENTS'
export const SET_FILE_EXPLORER_ROOT = 'SET_FILE_EXPLORER_ROOT'
